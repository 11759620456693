import { QueryParams } from '~/shared/utils/request/utils/buildURL';
import { Fetch, HeadersParam } from './models/fetch';
import { isSSR } from '~/shared/utils';

type ParamType =
    | {
          [key: string]: string | string[] | undefined;
      }
    | QueryParams;

type MethodType = 'POST' | 'GET' | 'PUT' | 'DELETE';

// Get arbitrary severity score for request time. Used for better grouping of logs from server.
const getPerformanceSeverity = (time: number) => {
    const seconds = time / 1000;
    if (seconds > 10) return 5;
    if (seconds > 5) return 4;
    if (seconds > 2) return 3;
    if (seconds > 1) return 2;
    return 1;
};

/**
 * Helper method for fetching content from the API
 */
export async function fetcher<T>(
    endpoint: string,
    params: ParamType = {},
    headers: HeadersParam = {},
    method: MethodType = 'GET',
    body = '',
    // TODO: Migrate other common params to this config and change params to be object instead of list
    additionalConfig: Omit<RequestInit, 'method' | 'headers' | 'body'> = {},
): Promise<Fetch<T>> {
    // Kill the request if it doesn't resolve in 30 seconds
    const signal = AbortSignal.timeout(30000);
    // Add query params
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
        searchParams.append(key, String(value));
    });
    const searchParamsQuery = searchParams.toString();
    const url = `${endpoint}${searchParamsQuery?.length ? `?${searchParamsQuery}` : ''}`;

    const startDate = new Date();
    // Filter out headers that should not be logged
    const blackListHeaders = ['authorization'];
    const loggedHeaders: { [key: string]: string } = {};

    Object.entries(headers).forEach(([key, value]) => {
        if (blackListHeaders.includes(key.toLowerCase())) return;
        loggedHeaders[key] = value;
    });
    if (isSSR) {
        // eslint-disable-next-line no-console
        console.info('Querying', url, loggedHeaders);
    }

    const response = await fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...headers,
        },
        body: method != 'GET' ? body : undefined,
        keepalive: true,
        signal,
        ...additionalConfig,
    });
    if (isSSR) {
        console.info('Querying done', url, loggedHeaders);
    }
    const { status, statusText } = response;
    const endDate = new Date();

    performanceLogging({
        endDate,
        startDate,
        url,
        statusCode: status,
        success: response.ok,
    });

    if (status >= 500) {
        console.error(`Failed to fetch API with url:${url}`);
        console.error(statusText);
    }

    return response;
}

type PerformanceLoggingParams = {
    startDate: Date;
    endDate: Date;
    url: string;
    statusCode: number;
    success: boolean;
};
const performanceLogging = (params: PerformanceLoggingParams) => {
    // Needs to run in server as we're usign the appInights object from Node instance.
    if (!isSSR || process.env.NODE_ENV !== 'production') return;
    const { startDate, endDate, url, success, statusCode } = params;
    const timing = Math.round(endDate.getTime() - startDate.getTime());
    const severity = getPerformanceSeverity(timing);
    const customProperties = {
        url,
        timing,
        severity,
    };

    if (severity >= 3) {
        globalThis?.appInsights?.trackEvent({
            name: 'SpaServerFetchSlowRequest',
            measurements: {
                timing: timing,
            },
            time: startDate,
            properties: customProperties,
        });
    }
    globalThis?.appInsights?.trackMetric({
        value: timing,
        name: 'SpaServerFetchRequestTime',
        properties: customProperties,
        time: startDate,
    });

    globalThis?.appInsights?.trackDependency({
        data: url,
        duration: timing,
        resultCode: statusCode,
        success: success,
        name: 'SpaServerFetch',
        dependencyTypeName: 'FETCH',
        properties: customProperties,
        time: startDate,
    });
    // eslint-disable-next-line no-console
    console.log(`Querying ${url} took ${timing}ms. Performance severity level: ${severity}`);
};
